<template>
  <article v-if="order.name" class="order-specification">
    <div class="order-specification-top">
      <div class="order-specification-top-left">
        <strong
          class="specification-invoice__content specification-invoice__title"
          >Invoice Number</strong
        >
        <p class="specification-invoice__content specification-invoice__data">
          {{ order.invoiceNo }}
        </p>
      </div>
      <div class="order-specification-top__logo">
        <img src="@/assets/logo-black.png" alt="" width="141" />
      </div>
    </div>
    <header class="order-specification-header">
      <h1 class="order-specification__title">INVOICE</h1>
      <p class="order-specification__title-sub">거래명세서</p>
    </header>
    <div class="order-specification-content">
      <p class="order-specification-info">판매자 {{ order.name }} 님</p>
      <table class="order-specification-table">
        <colgroup>
          <col style="width: 40%" />
          <col />
          <col />
          <col style="width: 8%" />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>작품정보</th>
            <th>판매가</th>
            <th>부가세</th>
            <th>합계금액</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="order-table-thumbnail">
                <img :src="order.image" alt="" class="order-item-img" />
                <p class="order-table__subject">
                  LOT# {{ order.lot }}<br />
                  {{ order.artistName || order.brandName }}<br />
                  {{ order.title }}
                </p>
              </div>
            </td>
            <td class="text-center">
              {{ convertNumWithComma(order.totalPrice, '') }}
            </td>
            <td class="text-center">
              {{ convertNumWithComma(order.vat, '') }}
            </td>
            <td class="text-center">
              {{ convertNumWithComma(order.payAmount, '') }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="text-right">합계</td>
            <td class="text-center">
              {{ convertNumWithComma(order.totalPrice, '') }}
            </td>
            <td class="text-center">
              {{ convertNumWithComma(order.vat, '') }}
            </td>
            <td class="text-center">
              {{ convertNumWithComma(order.payAmount, '') }}
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="price-area">
        <p class="price-content has-border">
          <span class="price-content__data price-content__title">작품수</span>
          <span class="price-content__data">
            <strong class="price-content__price">1</strong>
            <span class="price-content__unit">점</span>
          </span>
        </p>
        <p class="price-content">
          <span class="price-content__data price-content__title">판매가</span>
          <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(order.totalPrice, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
        </p>
        <p class="price-content">
          <span class="price-content__data price-content__title">부가세(VAT)</span>
          <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(order.vat, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
        </p>
        <p class="price-content has-border">
          <span class="price-content__data price-content__title">결제금액</span>
          <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(order.payAmount, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
        </p>
        <p class="price-content">
          <span class="price-content__data price-content__title">판매수수료</span>
          <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(order.totalFee, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
        </p>
        <p
          v-if="order.pgFee"
          class="price-content has-border"
        >
          <span class="price-content__data price-content__title">결제(PG)수수료</span>
          <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(order.pgFee || 0, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
        </p>
        <p class="price-content">
          <span class="price-content__data price-content__title">정산금액</span>
          <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(order.settlementAmount, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
        </p>
        <div class="mt-24">
          <p>위와 같이 정산하여 지급합니다.</p>
          <p class="mt-2">{{ format(new Date(order.settlementDate), 'yyyy년 MM월 dd일') }}</p>
          <p class="mt-2">주식회사 서울옥션</p>
        </div>
      </div>
    </div>
    <footer class="order-specification-footer">
      <span class="order-specification-partner__name">(주)서울옥션</span>
      <div class="order-specification-partner-info">
        대표자 : 이옥경 | 서울특별시 종로구 평창31길 11 (평창동 465-10) <br />
        02-395-0330 | cs@blacklot.com<br />
        사업자등록번호 : 101-86-31246
      </div>
    </footer>
  </article>
</template>

<script>
import { onMounted, ref } from 'vue';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';

export default {
  name: 'Specification',
  setup() {
    const route = useRoute();
    const orderId = route.query.orderId;
    const order = ref({});
    console.log(orderId);

    const fetchData = async () => {
      try {
        const { data } = await partnerAPI.partnerOrder.listMyOrderUsingGET2({
          orderId,
        });

        console.log('data', data);
        order.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      order,
      fetchData,
      convertNumWithComma,
      format,
    };
  },
};
</script>

<style lang="scss">
#__nuxt.width-auto {
  min-width: auto;
}
.order-specification {
  padding: 55px 80px;
  width: 1050px;
  height: 1500px;
  box-sizing: border-box;
  position: relative;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      .specification-invoice__content {
        font-family: 'Gilroy', Pretendard, -apple-system, BlinkMacSystemFont;
        &.specification-invoice {
          &__title {
            font-size: 20px;
            font-weight: 800;
          }
          &__data {
            margin-top: 4px;
          }
        }
      }
    }
    &__logo {
      width: 141px;
      height: 24px;
      //text-indent: -9999px;
      background-repeat: no-repeat;
      //background-image: url('~assets/images/common/logo-black.png');
      background-size: contain;
    }
  }
  &-header {
    margin-top: 100px;
    text-align: center;
  }
  &__title {
    width: 356px;
    font-family: 'Gilroy', Pretendard, -apple-system, BlinkMacSystemFont;
    font-size: 36px;
    font-weight: 800;
    line-height: 1.22;
    margin: 0 auto 12px;
    padding-bottom: 12px;
    letter-spacing: 1em;
    border-bottom: 1px solid #000;
    &-sub {
      letter-spacing: 0.5em;
      font-size: 32px;
      font-weight: normal;
      line-height: 1.125;
    }
  }
  &-content {
    margin-top: 60px;
    .order-specification-info {
      font-size: 18px;
    }
  }
  &-table {
    margin-top: 26px;
    width: 100%;
    .order {
      &-table-thumbnail {
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
      &-item-img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin-right: 16px;
        flex: none;
      }
    }
    thead {
      tr {
        border-top: 1px solid;
        th {
          padding: 16px 0;
          font-weight: normal;
        }
      }
    }
    tfoot {
      tr {
        border-bottom: 1px solid;
        td {
          padding: 16px 0;
          font-weight: normal;
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        td {
          padding: 10px 0;
        }
      }
    }
  }
  &-footer {
    width: calc(100% - 160px);
    left: 80px;
    bottom: 28px;
    position: absolute;
    border-top: 1px solid;
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    .order-specification-partner {
      &__name {
        font-size: 24px;
      }
      &-info {
        text-align: right;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 1.5;
      }
    }
  }
}
.price-area {
  margin-top: 120px;
  width: 344px;
  margin-left: auto;
  .price-content {
    display: flex;
    align-items: center;
    &.has-border {
      border-bottom: 1px solid #000000;
      padding-bottom: 14px;
    }
    & + .price-content {
      margin-top: 18px;
    }
    &__title {
      display: inline-block;
      min-width: 105px;
    }
    &__price {
      display: inline-block;
      min-width: 188px;
      font-weight: normal;
      text-align: right;
    }
    &__unit {
      min-width: 47px;
      display: inline-block;
      text-align: right;
    }
  }
}
</style>
